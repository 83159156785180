import * as React from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import styles from './FixedMenu.module.css';

export const FixedMenu = () => {
  const [active, setActive] = React.useState<string | null>(null);

  return (
    <div className={styles['fixedmenu']}>
      <ul className={styles['fixedmenu__list']}>
        <li
          className={clsx(
            styles['fixedmenu__list__item'],
            active == 'guest_my_page' ? styles['is-active'] : null
          )}
          onMouseEnter={() => {
            setActive('guest_my_page');
          }}
          onMouseLeave={() => {
            setActive(null);
          }}
        >
          <Link href={'/voucherauth'}>予約確認</Link>
        </li>
        <li
          className={clsx(
            styles['fixedmenu__list__item'],
            active == 'tickets' ? styles['is-active'] : null
          )}
          onMouseEnter={() => {
            setActive('tickets');
          }}
          onMouseLeave={() => {
            setActive(null);
          }}
        >
          <Link href={'/tickets'}>チケット表示</Link>
        </li>
      </ul>
    </div>
  );
};
