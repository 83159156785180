import * as React from 'react';

import { Customer } from 'models/customer';

export interface CustomerContextType {
  customer: Customer | null;
  fetchingCustomer: boolean;
  fetchCustomerError: string;
}

export const CustomerContext = React.createContext<CustomerContextType>({
  customer: null,
  fetchingCustomer: true,
  fetchCustomerError: '',
});
