import * as React from 'react';
import { NextPage } from 'next';
import { Dispatch } from 'redux';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useSelector } from 'react-redux';

import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { Home } from 'components/PrivateMarketplace/Home/Home';
import { PrivateMarketplaceLayout } from 'components/PrivateMarketplaceLayout/PrivateMarketplaceLayout';
import { BookingWidgetLayout } from 'components/BookingWidgetLayout/BookingWidgetLayout';
import { ProductList } from 'components/ProductList/ProductList';
import { wrapper } from 'ducks';
import {
  fetchSettingsServerSide,
  fetchProductSummariesServerSide,
  fetchCustomPagesServerSide,
} from 'ducks/server';
import { fetchConfigForHostname } from 'lib/util/fetchConfigForHostname';
import { withSSR } from 'withSSR';
import { getI18nServerSideProps } from 'i18n';
import { detectLocale } from 'i18n-server';
import { absoluteUrl } from 'lib/util/absoluetUrl';
import { selectPMPEnabled } from 'ducks/server/settings';
import { selectLanguageOptions } from 'ducks/server/settings';
import { languageOptionMap } from 'lib/util/i18nHelpers';
import { findPMPLinkForLocale } from 'lib/util/findPMPLinkForLocale';
import { CustomerProvider } from 'components/Customer/CustomerProvider';

interface Props {
  apiKey: string;
  host: string;
}

const Top: NextPage<Props> = ({ apiKey, host }) => {
  const router = useRouter();

  const pmpEnabled = useSelector(selectPMPEnabled);

  return (
    <ApiKeyContext.Provider
      value={{
        apiKey,
        useApiKeyInPaths: false,
      }}
    >
      <CustomerProvider>
        <Head>
          <meta property="og:url" content={`${host}${router.asPath}`} />
          <meta property="og:type" content={'website'} />
          <meta name="twitter:card" content={'summary_large_image'} />
        </Head>
        {pmpEnabled ? (
          <PrivateMarketplaceLayout>
            <Home />
          </PrivateMarketplaceLayout>
        ) : (
          <BookingWidgetLayout>
            <ProductList />
          </BookingWidgetLayout>
        )}
      </CustomerProvider>
    </ApiKeyContext.Provider>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(async ({ req, res, store }) => {
  const hostname = req.headers?.host?.split(':')[0] || '';
  const { apiKey, lang } = await fetchConfigForHostname(hostname);

  const dispatch: Dispatch<any> = store.dispatch;

  let i18nProps = {};

  let locale = lang;
  if (locale) {
    // If lang is initialized in config, this is a single-language PMP

    await Promise.all([
      dispatch(fetchSettingsServerSide(apiKey, '', locale, `https://${hostname}`)),
      dispatch(fetchProductSummariesServerSide(apiKey, locale)),
      dispatch(fetchCustomPagesServerSide(apiKey, locale)),
    ]);

    i18nProps = await getI18nServerSideProps(locale);
  } else {
    locale = await detectLocale(req, res);

    // Fetch settings using detected language
    await dispatch(fetchSettingsServerSide(apiKey, '', locale));

    // Verify that the detected language is in the supplier's supported languages. If it isn't,
    // default to the first supported language and refetch settings.
    const langOptions = selectLanguageOptions(store.getState());
    if (langOptions.length > 0 && !langOptions.some((langOpt) => langOpt.iso === locale)) {
      locale = langOptions[0].iso;
      await dispatch(fetchSettingsServerSide(apiKey, '', locale));
    }

    await Promise.all([
      dispatch(fetchProductSummariesServerSide(apiKey, locale)),
      dispatch(fetchCustomPagesServerSide(apiKey, locale)),
    ]);

    i18nProps = await getI18nServerSideProps(locale);

    if (langOptions.length === 1) {
      (req as any).locale = langOptions[0].iso;
    } else {
      const productSummaries = store.getState().server.productSummaries.all;
      if (productSummaries.length > 0) {
        const sourceLanguage = productSummaries[0]?.source_language || 'EN_US';
        (req as any).locale = languageOptionMap[sourceLanguage]?.iso;
      }
    }
  }

  // If there are private marketplaces but we are viewing as booking widget, redirect to a private marketplace.
  const pmpEnabled = !!store.getState().server.settings.all.private_marketplace;
  const pmpLinks = store.getState().server.settings.all.private_marketplace_links;
  if (!pmpEnabled && (pmpLinks ?? []).length > 0) {
    const pmpLink = findPMPLinkForLocale(pmpLinks, locale);
    if (pmpLink) {
      return {
        redirect: {
          destination: pmpLink.url,
          permanent: false,
        },
      };
    }
  }

  (req as any).locale = locale;

  return {
    props: {
      apiKey,
      host: absoluteUrl(req),
      ...i18nProps,
    },
  };
});

export default withSSR()(Top);
