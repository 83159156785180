import * as React from 'react';
import { useRouter } from 'next/router';

import { Footer } from 'components/Footer/Footer';
import { BookingWidgetHeader } from 'components/BookingWidgetHeader/BookingWidgetHeader';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { isEssentialPagePath } from 'lib/util/isEssentialPagePath';
import { LayoutContext } from 'contexts/LayoutContext';

type Props = {
  children: React.ReactNode;
};

export const BookingWidgetPage = ({ children }: Props) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);
  const { showHeaderFooter } = React.useContext(LayoutContext);

  const router = useRouter();

  const shouldShowHeader =
    (showHeaderFooter &&
      (bookingWidgetTheme !== 'VIBRANT' ||
        router.asPath?.includes('checkout') ||
        isEssentialPagePath(router.asPath ?? '') ||
        router.asPath?.includes('qrreader'))) ||
    router.asPath?.includes('imagedownload');

  return (
    <div className="base-wrap">
      {shouldShowHeader && (
        <>
          <BookingWidgetHeader />
        </>
      )}
      <main className="base-main">{children}</main>
      {showHeaderFooter && <Footer />}
    </div>
  );
};
