import * as React from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Head from 'next/head';
import clsx from 'clsx';
import { FixedMenu } from 'components/FixedMenu/FixedMenu';

import {
  selectCategories,
  selectFeatures,
  selectHeaderDescriptionText,
  selectPopularProductSummaries,
  selectRecommendedProductSummaries,
  selectTopPage,
  selectTopPageSections,
} from 'lib/util/privateMarketplace';
import { selectProductSummaries } from 'lib/util/productSummaryShape';
import { CardSlider } from 'components/PrivateMarketplace/CardSlider/CardSlider';
import { ProductCard } from 'components/PrivateMarketplace/ProductCard/ProductCard';
import { TourList } from 'components/PrivateMarketplace/TourList/TourList';
import { getThemeCss } from 'components/Themes';
import { ReduxState } from 'ducks';

import { PMPKeyVisualView } from './PMPKeyVisualView';
import { PMPCategoryListView } from './PMPCategoryListView';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { getThemeCssOverride } from 'components/Themes';
import { appendQueryString } from 'lib/util/appendQueryString';
import { useQueryString } from 'hooks/useQueryString';
import { getSupplierStructuredReviewData } from 'lib/util/getSupplierStructuredReviewData';

export const Home = () => {
  const { t } = useTranslation();

  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);
  const allProducts = useSelector(selectProductSummaries);
  const popularProducts = useSelector(selectPopularProductSummaries);
  const recommendedProducts = useSelector(selectRecommendedProductSummaries);
  const categories = useSelector(selectCategories);
  const features = useSelector(selectFeatures);
  const topPage = useSelector(selectTopPage);
  const topPageSections = useSelector(selectTopPageSections);
  const headerText = useSelector(selectHeaderDescriptionText);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const siteName = `${headerText} ${settings.supplier_name}`;
  const reviewCount = useSelector((state: ReduxState) => state.server.productSummaries.reviewCount);
  const reviewRating = useSelector(
    (state: ReduxState) => state.server.productSummaries.reviewRating
  );

  const bannerImageUrl = topPage?.banner_image_url || '';

  const title = topPage?.banner_title || '';
  const description = topPage?.banner_description || '';

  const twitter = (settings?.sns_ids || []).find((snsId) => {
    return snsId?.key === 'twitter';
  });

  const queryString = useQueryString();

  return (
    <>
      <Head>
        <title>{headerText}</title>
        {description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
          </>
        )}
        <meta property="og:title" content={headerText} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:image" content={bannerImageUrl} />
        {twitter && twitter?.id && <meta name="twitter:site" content={`@${twitter.id}`} />}
        {reviewCount > 0 && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(
                getSupplierStructuredReviewData(
                  bannerImageUrl,
                  settings.supplier_name,
                  reviewRating,
                  reviewCount
                )
              ),
            }}
          />
        )}
      </Head>
      <PMPKeyVisualView
        title={title}
        supplierName={settings.supplier_name}
        headerDescription={headerText}
        logoUrl={settings.logo_url}
        bannerTitle={title}
        bannerImageUrl={bannerImageUrl}
        bannerDescription={description}
      />

      <div className={clsx('base-topNav', getThemeCssOverride(bookingWidgetTheme, 'base-topNav'))}>
        <ul className="base-topNav__list maincolor-txt">
          {topPageSections.map((section) => {
            switch (section) {
              case 'POPULAR_PRODUCTS':
                return (
                  <a key="popular" href="/top#popular">
                    <li className="base-topNav__list__item">
                      <div className="maincolor-ic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <rect width="32" height="32" fill="none" />
                          <g>
                            <path
                              d="M32,9.53a2.43,2.43,0,1,0-4.18,1.69c-1.65,3.42-4.81,4.62-7.26,3.53-2.18-1-3-3.72-3.26-5.86a2.77,2.77,0,0,0,1.5-2.47,2.8,2.8,0,1,0-5.6,0,2.77,2.77,0,0,0,1.5,2.47c-.27,2.14-1.08,4.9-3.26,5.86C9,15.84,5.83,14.64,4.18,11.22a2.43,2.43,0,1,0-2.06.72l2.8,10.25H27.08l2.8-10.25A2.43,2.43,0,0,0,32,9.53Z"
                              fill="#0094cc"
                            />
                            <rect
                              x="3.56"
                              y="24.61"
                              width="24.88"
                              height="3.77"
                              rx="1.81"
                              fill="#0094cc"
                            />
                          </g>
                        </svg>
                      </div>
                      {t('Popularity Ranking')}
                    </li>
                  </a>
                );
              case 'RECOMMENDED_PRODUCTS':
                return (
                  <a key="recommended" href="#recommended">
                    <li className="base-topNav__list__item">
                      <div className="maincolor-ic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <rect width="32" height="32" fill="none" />
                          <g>
                            <rect x="6" y="3" width="4" height="26" rx="1.86" fill="#0094cc" />
                            <path
                              d="M28.22,11.72,8.8,3.23A2,2,0,0,0,6,5.06V23l22.22-9.72A.85.85,0,0,0,28.22,11.72Z"
                              fill="#0094cc"
                            />
                          </g>
                        </svg>
                      </div>
                      {t('Recommended')}
                    </li>
                  </a>
                );
              case 'CATEGORY':
                return (
                  <a key="category" href="#category">
                    <li className="base-topNav__list__item">
                      <div className="maincolor-ic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <rect width="32" height="32" fill="none" />
                          <g>
                            <path
                              d="M21,3H7A2,2,0,0,0,5,5V27a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V9ZM8,8.75A.76.76,0,0,1,8.75,8h7.5a.76.76,0,0,1,.75.75v.5a.76.76,0,0,1-.75.75H8.75A.76.76,0,0,1,8,9.25ZM23,24H9a1,1,0,0,1,0-2H23a1,1,0,0,1,0,2Zm0-7H9a1,1,0,0,1,0-2H23a1,1,0,0,1,0,2Zm-3-7V4l6,6Z"
                              fill="#0094cc"
                            />
                          </g>
                        </svg>
                      </div>
                      {t('Categories')}
                    </li>
                  </a>
                );
              case 'FEATURE':
                return (
                  <a key="feature" href="#feature">
                    <li className="base-topNav__list__item">
                      <div className="maincolor-ic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <rect width="32" height="32" fill="none" />
                          <g>
                            <path
                              d="M29.1,16.78a1.39,1.39,0,0,1,0-1.56l.71-.94A.9.9,0,0,0,29.63,13l-.05,0-1-.64A1.39,1.39,0,0,1,28,10.85l.34-1.13a.89.89,0,0,0-.61-1.13l-.07,0-1.15-.28a1.38,1.38,0,0,1-1-1.19l-.07-1.17a.93.93,0,0,0-1-.87h-.06l-1.18.13a1.42,1.42,0,0,1-1.38-.78l-.47-1.07a.93.93,0,0,0-1.21-.49l-.06,0-1.06.52a1.42,1.42,0,0,1-1.57-.27l-.81-.85a.94.94,0,0,0-1.31,0l0,0-.82.85a1.41,1.41,0,0,1-1.56.27l-1.06-.52a.93.93,0,0,0-1.24.4l0,.06-.48,1.07a1.4,1.4,0,0,1-1.37.78L7.58,5.07a.91.91,0,0,0-1,.79.15.15,0,0,0,0,.07L6.47,7.1a1.38,1.38,0,0,1-1,1.19L4.3,8.57a.91.91,0,0,0-.7,1.09.14.14,0,0,0,0,.06L4,10.85a1.41,1.41,0,0,1-.54,1.47l-1,.64a.9.9,0,0,0-.27,1.26l0,.06.71.94a1.39,1.39,0,0,1,0,1.56l-.71.94A.9.9,0,0,0,2.37,19l0,0,1,.64A1.41,1.41,0,0,1,4,21.15l-.34,1.13a.91.91,0,0,0,.61,1.14H4.3l1.15.27a1.41,1.41,0,0,1,1,1.2l.07,1.17a.93.93,0,0,0,1,.87h.06l1.18-.14a1.4,1.4,0,0,1,1.37.79l.48,1.07a.93.93,0,0,0,1.21.49l.06,0,1.06-.52a1.41,1.41,0,0,1,1.56.27l.82.85a.94.94,0,0,0,1.31.05s0,0,.05-.05l.81-.85a1.42,1.42,0,0,1,1.57-.27l1.06.52a.93.93,0,0,0,1.24-.39l0-.07.47-1.07a1.43,1.43,0,0,1,1.38-.79l1.18.14a.92.92,0,0,0,1-.79s0-.05,0-.07l.07-1.17a1.41,1.41,0,0,1,1-1.2l1.15-.27a.91.91,0,0,0,.7-1.09s0,0,0-.06L28,21.15a1.41,1.41,0,0,1,.54-1.47l1-.64a.91.91,0,0,0,.27-1.26l0-.06ZM21.66,11.4l.7.71a1,1,0,0,1,0,1.42l-5.65,5.65L15.29,20.6a1,1,0,0,1-1.41,0l-1.42-1.42h0L9.64,16.35a1,1,0,0,1,0-1.41l.7-.71a1,1,0,0,1,1.42,0l2.83,2.83,5.65-5.66A1,1,0,0,1,21.66,11.4Z"
                              fill="#0094cc"
                            />
                          </g>
                        </svg>
                      </div>
                      {t('Featured')}
                    </li>
                  </a>
                );
              case 'PRODUCT_LIST':
                return (
                  <a key="tour-list" href="#tour-list">
                    <li className="base-topNav__list__item">
                      <div className="maincolor-ic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <rect width="32" height="32" fill="none" />
                          <g>
                            <path
                              d="M7.5,24.5V10a1,1,0,0,0-1-1h-1a1,1,0,0,0-1,1V27.5A1.5,1.5,0,0,0,6,29H21.5a1,1,0,0,0,1-1V27a1,1,0,0,0-1-1H9A1.5,1.5,0,0,1,7.5,24.5Z"
                              fill="#0094cc"
                            />
                            <path
                              d="M21.5,4h-11a1,1,0,0,0-1,1V23a1,1,0,0,0,1,1h16a1,1,0,0,0,1-1V10Zm-1,7V6l5,5Z"
                              fill="#0094cc"
                            />
                          </g>
                        </svg>
                      </div>
                      {t('All Products')}
                    </li>
                  </a>
                );
            }

            return null;
          })}
        </ul>
        <div className="base-topNav__arrow subcolor-bg maincolor-arrow2" />
      </div>
      {topPageSections.map((section, idx) => {
        switch (section) {
          case 'POPULAR_PRODUCTS':
            return (
              <div
                key={idx}
                className={clsx(
                  'base-topRanking',
                  'scroll-target-section',
                  getThemeCssOverride(bookingWidgetTheme, 'base-topRanking')
                )}
                id="popular"
              >
                <div className="base-topRanking__header">
                  <div
                    className={clsx(
                      'base-headline',
                      getThemeCss(bookingWidgetTheme, 'base-headline')
                    )}
                  >
                    <div className="maincolor-ic">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <rect width="32" height="32" fill="none" />
                        <g>
                          <path
                            d="M32,9.53a2.43,2.43,0,1,0-4.18,1.69c-1.65,3.42-4.81,4.62-7.26,3.53-2.18-1-3-3.72-3.26-5.86a2.77,2.77,0,0,0,1.5-2.47,2.8,2.8,0,1,0-5.6,0,2.77,2.77,0,0,0,1.5,2.47c-.27,2.14-1.08,4.9-3.26,5.86C9,15.84,5.83,14.64,4.18,11.22a2.43,2.43,0,1,0-2.06.72l2.8,10.25H27.08l2.8-10.25A2.43,2.43,0,0,0,32,9.53Z"
                            fill="#0094cc"
                          />
                          <rect
                            x="3.56"
                            y="24.61"
                            width="24.88"
                            height="3.77"
                            rx="1.81"
                            fill="#0094cc"
                          />
                        </g>
                      </svg>
                    </div>
                    <h2 className="base-key__text">{t('Popularity Ranking')}</h2>
                  </div>
                  <Link
                    href={appendQueryString('/top/popular', queryString)}
                    className="base-topRanking__header__btn maincolor-txt maincolor-arrow2"
                  >
                    {t('See More')}
                  </Link>
                </div>
                <CardSlider
                  data={popularProducts}
                  renderElement={(product) => (
                    <Link href={appendQueryString(`/top/products/${product.id}`, queryString)}>
                      <ProductCard displayLabelTypes={['RANKING_SPECIFIC']} product={product} />
                    </Link>
                  )}
                />
              </div>
            );
          case 'RECOMMENDED_PRODUCTS':
            return (
              <div
                key={idx}
                className={clsx(
                  'base-topRecommend',
                  'scroll-target-section',
                  getThemeCssOverride(bookingWidgetTheme, 'base-topRecommend')
                )}
                id="recommended"
              >
                <div className="base-topRecommend__header">
                  <div
                    className={clsx(
                      'base-headline',
                      getThemeCss(bookingWidgetTheme, 'base-headline')
                    )}
                  >
                    <div className="maincolor-ic">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <rect width="32" height="32" fill="none" />
                        <g>
                          <rect x="6" y="3" width="4" height="26" rx="1.86" fill="#0094cc" />
                          <path
                            d="M28.22,11.72,8.8,3.23A2,2,0,0,0,6,5.06V23l22.22-9.72A.85.85,0,0,0,28.22,11.72Z"
                            fill="#0094cc"
                          />
                        </g>
                      </svg>
                    </div>
                    <h2 className="base-key__text">{t('Recommended Products')}</h2>
                  </div>
                  <Link
                    href={appendQueryString('/top/recommended', queryString)}
                    className="base-topRecommend__header__btn maincolor-txt maincolor-arrow2"
                  >
                    {t('See More')}
                  </Link>
                </div>
                <CardSlider
                  data={recommendedProducts}
                  renderElement={(product) => (
                    <Link href={appendQueryString(`/top/products/${product.id}`, queryString)}>
                      <ProductCard displayLabelTypes={['RECOMMENDED']} product={product} />
                    </Link>
                  )}
                />
              </div>
            );
          case 'CATEGORY':
            return (
              <section
                key={idx}
                className={clsx(
                  'base-topCategory',
                  'scroll-target-section',
                  getThemeCssOverride(bookingWidgetTheme, 'base-topCategory')
                )}
                id="category"
              >
                <div className="base-topCategory__inner">
                  <div className="base-topCategory__header">
                    <div
                      className={clsx(
                        'base-headline',
                        'center',
                        getThemeCss(bookingWidgetTheme, 'base-headline')
                      )}
                    >
                      <div className="maincolor-ic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <rect width="32" height="32" fill="none" />
                          <g>
                            <path
                              d="M21,3H7A2,2,0,0,0,5,5V27a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V9ZM8,8.75A.76.76,0,0,1,8.75,8h7.5a.76.76,0,0,1,.75.75v.5a.76.76,0,0,1-.75.75H8.75A.76.76,0,0,1,8,9.25ZM23,24H9a1,1,0,0,1,0-2H23a1,1,0,0,1,0,2Zm0-7H9a1,1,0,0,1,0-2H23a1,1,0,0,1,0,2Zm-3-7V4l6,6Z"
                              fill="#0094cc"
                            />
                          </g>
                        </svg>
                      </div>
                      <h2 className="base-key__text">{t('Categories')}</h2>
                    </div>
                  </div>
                  <PMPCategoryListView categories={categories} />
                </div>
              </section>
            );
          case 'FEATURE':
            return (
              <section
                key={idx}
                className={clsx(
                  'base-topFeature',
                  'scroll-target-section',
                  getThemeCssOverride(bookingWidgetTheme, 'base-topFeature')
                )}
                id="feature"
              >
                <div className="base-topFeature__inner">
                  <div className="base-topFeature__header">
                    <div
                      className={clsx(
                        'base-headline',
                        'center',
                        getThemeCss(bookingWidgetTheme, 'base-headline')
                      )}
                    >
                      <div className="maincolor-ic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <rect width="32" height="32" fill="none" />
                          <g>
                            <path
                              d="M29.1,16.78a1.39,1.39,0,0,1,0-1.56l.71-.94A.9.9,0,0,0,29.63,13l-.05,0-1-.64A1.39,1.39,0,0,1,28,10.85l.34-1.13a.89.89,0,0,0-.61-1.13l-.07,0-1.15-.28a1.38,1.38,0,0,1-1-1.19l-.07-1.17a.93.93,0,0,0-1-.87h-.06l-1.18.13a1.42,1.42,0,0,1-1.38-.78l-.47-1.07a.93.93,0,0,0-1.21-.49l-.06,0-1.06.52a1.42,1.42,0,0,1-1.57-.27l-.81-.85a.94.94,0,0,0-1.31,0l0,0-.82.85a1.41,1.41,0,0,1-1.56.27l-1.06-.52a.93.93,0,0,0-1.24.4l0,.06-.48,1.07a1.4,1.4,0,0,1-1.37.78L7.58,5.07a.91.91,0,0,0-1,.79.15.15,0,0,0,0,.07L6.47,7.1a1.38,1.38,0,0,1-1,1.19L4.3,8.57a.91.91,0,0,0-.7,1.09.14.14,0,0,0,0,.06L4,10.85a1.41,1.41,0,0,1-.54,1.47l-1,.64a.9.9,0,0,0-.27,1.26l0,.06.71.94a1.39,1.39,0,0,1,0,1.56l-.71.94A.9.9,0,0,0,2.37,19l0,0,1,.64A1.41,1.41,0,0,1,4,21.15l-.34,1.13a.91.91,0,0,0,.61,1.14H4.3l1.15.27a1.41,1.41,0,0,1,1,1.2l.07,1.17a.93.93,0,0,0,1,.87h.06l1.18-.14a1.4,1.4,0,0,1,1.37.79l.48,1.07a.93.93,0,0,0,1.21.49l.06,0,1.06-.52a1.41,1.41,0,0,1,1.56.27l.82.85a.94.94,0,0,0,1.31.05s0,0,.05-.05l.81-.85a1.42,1.42,0,0,1,1.57-.27l1.06.52a.93.93,0,0,0,1.24-.39l0-.07.47-1.07a1.43,1.43,0,0,1,1.38-.79l1.18.14a.92.92,0,0,0,1-.79s0-.05,0-.07l.07-1.17a1.41,1.41,0,0,1,1-1.2l1.15-.27a.91.91,0,0,0,.7-1.09s0,0,0-.06L28,21.15a1.41,1.41,0,0,1,.54-1.47l1-.64a.91.91,0,0,0,.27-1.26l0-.06ZM21.66,11.4l.7.71a1,1,0,0,1,0,1.42l-5.65,5.65L15.29,20.6a1,1,0,0,1-1.41,0l-1.42-1.42h0L9.64,16.35a1,1,0,0,1,0-1.41l.7-.71a1,1,0,0,1,1.42,0l2.83,2.83,5.65-5.66A1,1,0,0,1,21.66,11.4Z"
                              fill="#0094cc"
                            />
                          </g>
                        </svg>
                      </div>
                      <h2 className="base-key__text">{t('Featured')}</h2>
                    </div>
                  </div>
                  <PMPCategoryListView categories={features} />
                </div>
              </section>
            );
          case 'PRODUCT_LIST':
            return (
              <div
                key={idx}
                className={clsx(
                  'scroll-target-section',
                  getThemeCssOverride(bookingWidgetTheme, 'base-tourList')
                )}
                id="tour-list"
              >
                <TourList
                  products={allProducts}
                  filterTypes={['CATEGORY', 'FEATURE', 'TAG']}
                  displayLabelTypes={['RECOMMENDED', 'RANKING_GENERAL', 'PROMOTION']}
                />
              </div>
            );
        }

        return null;
      })}
      {!process.env.NEXT_PUBLIC_DISABLE_FIXED_MENU && <FixedMenu />}
    </>
  );
};
