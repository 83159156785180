import config from 'config';
import { Reservation } from 'models/reservation';
import moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

export const getCommonRedirectBaseUrl = () => {
  const host = window.location.host || 'localhost:8080';

  if (host.startsWith('localhost')) {
    return 'http://localhost:8080';
  }

  switch (process.env.NEXT_PUBLIC_ENV_NAME) {
    case 'production':
      return 'https://book.ntmg.com';
    case 'staging':
      return 'https://book.stg.ntmg.com';
    default:
      return 'https://book.dev.ntmg.com';
  }
};

export const getCommonRedirectUri = () => {
  return `${getCommonRedirectBaseUrl()}/authredirect`;
};

export const continueWithGoogleHandler = (
  redirectUri: string,
  afterAuthRedirectPath: any = null
) => {
  const authUri = getAuthUrl('GOOGLE', redirectUri, afterAuthRedirectPath);

  window.location.href = authUri;
};

export const continueWithFacebookHandler = (
  redirectUri: string,
  afterAuthRedirectPath: any = null
) => {
  const authUri = getAuthUrl('FACEBOOK', redirectUri, afterAuthRedirectPath);

  window.location.href = authUri;
};

export const continueWithSnsHandler = (
  idProvider: 'GOOGLE' | 'FACEBOOK',
  redirectUri: string,
  afterAuthRedirectPath: any = null
) => {
  const authUri = getAuthUrl(idProvider, redirectUri, afterAuthRedirectPath);

  window.location.href = authUri;
};

export const getAuthUrl = (
  idProvider: 'GOOGLE' | 'FACEBOOK',
  redirectUri: string,
  afterAuthRedirectPath: any = null
): string => {
  const commonRedirectUri = `${getCommonRedirectBaseUrl()}/authredirect`;

  const state = {
    redirectUri: `${redirectUri}`,
    idProvider,
    afterAuthRedirectPath,
  };

  const stateString = btoa(unescape(encodeURIComponent(JSON.stringify(state))));

  switch (idProvider) {
    case 'GOOGLE':
      return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
        config.googleAuth.clientId
      }&redirect_uri=${encodeURIComponent(
        commonRedirectUri
      )}&scope=${config.googleAuth.scopes
        .map((scope) => encodeURIComponent(scope))
        .join(
          ' '
        )}&response_type=code&approve_prompt=force&access_type=offline&state=${stateString}`;
    case 'FACEBOOK':
      return `https://www.facebook.com/v16.0/dialog/oauth?client_id=${
        config.facebookAuth.clientId
      }&redirect_uri=${encodeURIComponent(
        commonRedirectUri
      )}&scope=${config.facebookAuth.scopes
        .map((scope) => encodeURIComponent(scope))
        .join(' ')}&response_type=code&state=${stateString}`;
    default:
      return '';
  }
};

export const isReservationCancelled = (reservation: Reservation): boolean => {
  return [
    'WITHDRAWN_BY_AGENT',
    'CANCELED_BY_AGENT',
    'CANCELED_BY_GUEST',
    'CANCELED_BY_SUPPLIER',
    'DECLINED_BY_SUPPLIER',
  ].includes(reservation.status);
};

export const isChangedReservation = (reservation: Reservation): boolean => {
  return Boolean(reservation.changed_to_reservation_id);
};

export const getFeatureReservations = (reservations: Reservation[], now: Moment): Reservation[] => {
  return reservations.filter((reservation) => {
    if (isReservationCancelled(reservation)) {
      return false;
    }
    if (isChangedReservation(reservation)) {
      return false;
    }
    if (isRedeemedFreepassReservation(reservation)) {
      return false;
    }
    return moment
      .tz(reservation.start_date_time_utc, reservation.start_timezone)
      .isSameOrAfter(now);
  });
};

export const getPastReservations = (reservations: Reservation[], now: Moment): Reservation[] => {
  return reservations.filter((reservation) => {
    if (isReservationCancelled(reservation)) {
      return false;
    }
    if (isChangedReservation(reservation)) {
      return false;
    }
    if (isRedeemedFreepassReservation(reservation)) {
      return true;
    }
    return moment.tz(reservation.start_date_time_utc, reservation.start_timezone).isBefore(now);
  });
};

export const getCancelledReservations = (reservations: Reservation[]): Reservation[] => {
  return reservations.filter((reservation) => {
    return isReservationCancelled(reservation) && !isChangedReservation(reservation);
  });
};

export const isRedeemedFreepassReservation = (reservation: Reservation): boolean => {
  if (reservation?.free_start_date_time_from) {
    if ((reservation.checkin_info.checkin_records || []).length > 0) {
      return true;
    }
  }
  return false;
};
